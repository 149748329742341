<template>
  <section class="edit-category">
    
    <div class="page-header">
      <h3 class="page-title">Shipping Services</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/settings/shipping-list" class="text-primary">Shipping Services
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Shipping Services</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="editShippingService">
      <div class="row" v-if="shipping">

        <div class="col-lg-6">
          <div class="card mb-0">
            <div class="card-body">
              <div class="form-group  mb-2">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="shipping.name" class="form-control" />
                </div>
              </div>
            
              <div>
                <div class="form-group   mb-2">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <textarea class="form-control" rows="3" v-model="shipping.description"></textarea>

                </div>
              </div>
              <div class="form-group mb-0 ">
                <label for=""> Status </label>
                <div class="form-group mb-0">
                  <toggle-button v-model="shipping.active" :labels="{
                    checked: 'true',
                    unchecked: 'false',
                  }" />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
         
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Image </label>

                <div class="form-group">
                  <vue-dropzone ref="icon" id="icon " :options="dropzoneOptions" :useCustomSlot="true"
                    v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                    <div class="dropzone-custom-content">
                      <div v-if="info">
                        <div class="image-size-dropzone">
                          <img :src="`${imageApiURL}/${info.path}`" class="show-img img-fluid" />
                        </div>
                      </div>
                      <div v-if="!info" class="pt-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                          fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-upload" color="">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                      </div>
                      <div class="subtitle pt-1 pb-0">
                        <span> Drop File or </span>
                        <span style="color: #eb2027"> Browse</span>
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </div>
             
         
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary"> Update</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import { setingsApi } from "../../../api";

export default {
  name: "edit-shipping-services",
  props: {
    edit: {
      active: Boolean,
      name: String,
      description: String,
      image_id: Number,
    },
  },
  components: {
    
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      shipping: this.edit,
      
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      info: "",
      imageApiURL,
    };
  },
  mounted() {
    this.shippingModeById();
  },
  methods: {
    async shippingModeById() {
      
      const { data } = await setingsApi.shippingServiceById(this.$route.params.id);
      let shippings = data.data.data;
      shippings.forEach((val) => {
        this.shipping = val;
        if (this.shipping.image_id) {
          this.shippingIconById();
        }
        if (this.shipping.active == 1) {
          this.shipping.active = true;
        } else {
          this.shipping.active = false;
        }
      });
      
    },
    shippingIconById() {
      
      axios
        .get(`${mediaApi}/files/${this.shipping.image_id}`)
        .then((response) => {
          this.info = response.data.data[0];
          
        });
    },
    sendImages(file, xhr, formData) {
      let pages = "pages";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", pages);
    },
    success(file, response) {
      this.shipping.image_id = response.data.id;
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async editShippingService() {
      if (this.shipping.active === true) {
        this.shipping.active = 1;
      } else {
        this.shipping.active = 0;
      }

      for (var key in this.shipping) {
        if (this.shipping.hasOwnProperty(key)) {
          var val = this.shipping[key];
          if (val === null) {
            delete this.shipping[key];
          }
        }
      }
      
      const data = await setingsApi.updateShippingService(this.$route.params.id, {
        ...this.shipping,
      });
      
      this.$router.push("/settings/shipping-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

